<template>
    <div class="bg-mineralgreen py-10">
        <div class="container mx-auto">
            <div class="row justify-center ">
                <div class="col-12 col-sm-6 col-md-2 col-lg-2 p-8 text-white">
                    <q-img width="100%" src="@/assets/media/brands/ibc-international-bio-cosmetics-inverse-pastelgolden-icon.svg" no-spinner no-transition />
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-3 p-8 text-white">
                    <div class="col-12 flex flex-col justify-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Follow us on :</h1>
                        <div class="bg-skincolor h-1 mt-3 w-32"></div>
                        <div class="col-12 flex flex-row mt-5">
                            <q-btn color="lighty" icon="mdi-facebook" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                            <q-btn color="lighty" icon="mdi-instagram" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                            <q-btn color="lighty" icon="mdi-twitter" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                            <q-btn color="lighty" icon="mdi-linkedin" size="30px" padding="5px" flat round unelevated class="mr-3"/>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-7 p-8">
                    <div class="col-12 flex flex-col justify-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">Subscribe to our newsletter:</h1>
                        <div class="bg-skincolor h-1 mt-3 w-32"></div>
                        <div class="col-12 flex flex-row">
                            <p class="text-white py-3">Subscribe to our newsletter to learn more about our history, <br> receive expert beauty advice and keep you informed of our new products !</p>
                            <input type="email" v-model="form.email" placeholder="Type your email address" class="form-input border-pastelgolden-dark focus:border-pastelgolden-dark rounded-md text-mineralgreen w-6/12 mr-4 focus:ring-0" />
                            <button @click.prevent="newsletter" :disabled="v$.form.$invalid" type="sumbit" class="bg-skincolor hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-md w-3/12 mr-4">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-mineralgreen-dark border-t border-mineralgreen-dark"></div>

            <div class="row py-10 justify-center p-5">
                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Our Brand</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Enterprise</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Mission and Values</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">A word from the Director</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Our Commitments</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Our Know-How</a></li>
                    </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Menu</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Naturals ingredients</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Advice expert</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Faq</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Sitemap</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Help</a></li>
                    </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Our Products</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Body</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Face</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Hair</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Hygiene</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">More...</a></li>
                    </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-2">
                    <p class="text-white text-xl font-semibold mt-5">Social media</p>
                    <ul class="list-none mt-5">
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Facebook</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Twitter</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Instagram</a></li>
                        <li class="leading-7"><a class="text-lighty hover:text-white" href="">Linkedin</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useValidate from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import Mailer from "@/app/services/mailer.service"
import { Notify } from "quasar"
import Message from "@/app/config/message"

export default {
    name: "Footer",
    data() {
        return {
            v$: useValidate(),
            form: {
                email: null,
            },
        }
    },
    validations() { // validation form
        return {
            form: {
                email: { required, email },
            },
        }
    },
    methods: {
        newsletter () {
            Mailer
                .newsletter(this.form)
                .then((response) => {
                    if (response) {
                        Notify.create(response.data.message)
                    }
                    Notify.create(Message.notsent)
                })
                .catch((error) => {
                    if (error.response.data) {
                        Notify.create(Message.failed)
                        Notify.create(error.response.data.message)
                    }
                    Notify.create(Message.error)
                })
        }
    },
    created() {},
}
</script>

<style>

</style>
